.admin_main {
  height: 100vh;
  margin: 0 auto;
}

.admin_container {
  padding: 1rem;
}

.household_list {
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  height: 100%;
  margin: 3rem 10% 0 10%;
}

.household_list_item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 1rem;
  margin: 0.5rem 0;
}

.household_list_item * {
  margin-right: 2rem;
}

.family_names:hover {
  color: rgba(70, 187, 16, 0.377);
  cursor: pointer;
}

.box-for {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.box-for h3 {
  font-size: 1.2rem;
  border: black solid 1px;
  padding: 0px;
  margin: 0px;
  width: 300px;
  height: auto;
  overflow-wrap: break-word;
}

.box-for textarea {
  border: black solid 1px;
  height: 5rem;
  width: 300px;
  font-size: 1.2rem;
}

.box-for input {
  border: black solid 1px;
  height: auto;
  width: 300px;
  font-size: 1.2rem;
}

.radio_box {
  display: flex;
  justify-content: space-evenly;
  width: 300px;
  border: black solid 1px;
}

.just_flex {
  display: flex;
}
.popup-inner-admin {
  position: relative;
  padding: 48px;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  background-color: var(--newWhite);
  overflow: hidden;
  overflow-y: scroll;
}

.toggle_completed_box {
  text-align: center;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
}

.flexbox_pad {
  display: flex;
  justify-content: flex-start;
}

.flexbox_pad * {
  margin-right: 2rem;
}

.right_side {
  float: right;
  display: flex;
  flex-direction: column;
}

@media print {
  .popup-inner-admin {
    overflow: visible;
    display: grid;
    grid-template-columns: 45% 45%;
    grid-gap: 10%;
  }

  .popup-inner-admin button {
    display: none;
  }

  .box-for h3 {
    font-size: 1.2rem;
    border: black solid 1px;
    padding: 0px;
    margin: 0px;
    width: 8rem;
    height: auto;
  }
}
