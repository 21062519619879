.edit_popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(151, 151, 151, 0.398);

  display: flex;
  justify-content: center;
  align-items: center;
}

.edit_popup_inner {
  position: relative;
  padding: 50px;
  width: 100%;
  height: 100%;
  max-width: 700px;
  background-color: var(--newWhite);
  overflow: hidden;
  overflow-y: scroll;
  opacity: 1;
}
