.header_container {
  display: flex;
  justify-content: flex-start;
  background-color: grey;
}

.head_link {
  background-color: gray;
  margin: 0 0.7rem;
  font-size: 2rem;
  text-decoration: none;
}

.move_right {
  margin-left: auto;
}
