@import "./BtnStuff.css";
@import "./Popouts.css";
@import "./CssInNeedOfHome.css";
@import "./Footer.css";

@import "./Admincss/Header.css";
@import "./Admincss/Admin.css";
@import "./Admincss/AdminLogin.css";
@import "./Admincss/EditPopup.css";

:root {
  --maxWidth: 1280px;
  --green: #6cc993;
  --blue: #217b86;
  --oldWhite: #f7f7ef;
  --newWhite: #ffffff;
}

* {
  box-sizing: border-box;
  font-family: "Abel", sans-serif;
  margin: 0;
  padding: 0;
  background-color: var(--newWhite);
}

.app {
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  color: var(--green);
  font-size: 4rem;
}

h2 {
  font-size: 1.6rem;
  padding: 0.3rem 0.6rem;
  color: var(--blue);
}

h3 {
  margin-top: 2rem;
  font-size: 2rem;
  font-weight: normal;
  color: var(--blue);
}

h4 {
  font-size: 2rem;
  font-weight: normal;
  color: var(--blue);
}

p {
  font-size: 2rem;
  margin: 2rem 0;
  text-align: center;
}

a {
  color: var(--blue);
}

a:hover {
  color: rgba(187, 107, 16, 0.377);
}

input {
  text-align: center;
  border-color: white;
  background-color: white;
  font-size: 1.6rem;
}

#people-container {
  display: flex;
}

.people-container-child {
  padding: 0.5rem;
  font-size: 1.5rem;
}

.input-container {
  display: flex;
  margin: 0.5rem 0;
}

.shirt__size__container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.list__box {
  margin-left: 0.8rem;
}

.btn {
  background-color: rgb(108, 201, 147);
  font-size: 1.5rem;
  color: var(--white);
  margin: 0.3rem 0;
  padding: 16px 36px;
  border-radius: 30px;
  width: 200px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.btn:hover {
  background-color: darkgreen;
}

#remove-btn {
  color: rgb(187, 107, 16);
  cursor: pointer;
}

#remove-btn:hover {
  color: rgba(187, 107, 16, 0.377);
}

#languages-container {
  display: flex;
  border-radius: 5px;
  border: 3px solid rgb(108, 201, 147);
  background-color: rgb(108, 201, 147);
  padding: 0;
  margin: 0 5% 0 80%;
  float: right;
}

.languages-btn {
  border: 3px solid rgb(108, 201, 147);
  border-radius: 10px;
  padding: 0.2rem 0.4rem;
  margin: 0;
  background-color: rgb(187, 107, 16);
}

.languages-btn:hover {
  background-color: rgba(187, 107, 16, 0.377);
  cursor: pointer;
}

.required-star {
  color: red;
  font-size: 2rem;
}

@media (max-width: 800px) {
  h1 {
    font-size: 2rem;
  }

  p {
    font-size: 1.2rem;
  }

  /* .input-container {
    display: inline-block;
    margin: 0.5rem 20%;
  } */

  input[type="text"] {
    width: 15rem;
  }
}

@media (max-width: 350px) {
  input[type="text"] {
    width: 12rem;
  }
}
